import React from 'react';
import '../projects/style.css';
import {
  Container,
  Row,
  Col,
} from 'react-bootstrap';
import PCD from '../project_card_details';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub } from '@fortawesome/free-brands-svg-icons';

function Projects() {
  return (
    <Container className="project_main" fluid>
      <Row className="project_header">
      <Col xl={7}>
          <h1>Projects</h1>
      </Col>
      <Col xl={5}>
        <a href="https://www.github.com/sunilvijayan7/" target="_blank" rel="noopener noreferrer" className="right">
        <FontAwesomeIcon className="left_icon" icon={faGithub} /> <br/><br/>Navigate to Github
        </a>
      </Col>
      </Row>
      <PCD />
    </Container>
  );
}

export default Projects;
