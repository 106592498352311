import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from '../src/nav';
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.min.css';
import firebase from 'firebase';
var firebaseConfig = {
    apiKey: "AIzaSyDjy-AoyUa2cfU4yAF-WK1nvHYx5E1ecAI",
    authDomain: "portfolio-e7af4.firebaseapp.com",
    projectId: "portfolio-e7af4",
    storageBucket: "portfolio-e7af4.appspot.com",
    messagingSenderId: "419242382647",
    appId: "1:419242382647:web:805f5baaead3cba82976b2",
    measurementId: "G-14ZZVDFVS6"
    };
    // Initialize Firebase
    if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
    }
    firebase.analytics();
    const db = firebase.firestore();
    db.settings({
      timestampsInSnapshots: true
    });
    const date = new Date();
    const dateAsString = date.toString();
    const timezone = dateAsString.match(/\(([^\)]+)\)$/)[1];
    var isOpera = !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;
    var isEdge = navigator.userAgent.indexOf("Edge") > -1;
    var isChrome = !!window.chrome && !isOpera && !isEdge;
    var isIE= typeof document !== 'undefined' && !!document.documentMode && !isEdge;
    var isFirefox = typeof window.InstallTrigger !== 'undefined';
    var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    // const isOpera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;
    // const isFirefox = typeof InstallTrigger !== 'undefined';
    // const isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification));
    // const isIE = /*@cc_on!@*/false || !!document.documentMode;
    // const isEdge = !isIE && !!window.StyleMedia;
    // const isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
    // const isBlink = (isChrome || isOpera) && !!window.CSS;
    var browserName=!!isOpera ? "Opera" : !!isFirefox ? "Firefox" : !!isSafari ? "Safari" : !!isIE ? "Internat Explorer" : isEdge ? "Edge" : !!isChrome ? "Chrome" : "Blink";
    console.log(browserName);
    async function fetchMoviesJSON() {
        const response = await fetch('https://geolocation-db.com/json/e4f42070-ad2d-11eb-adf1-cf51da9b3410');
        const data = await response.json();
        return data;
      }
      fetchMoviesJSON().then(data => {
        Object.keys(data).map(function(keyName, keyIndex) {
          console.log(data[keyName]);
        });
          const userRef = db.collection("loginusers").add({
          city: data.city,
          countryCode: data.country_code,
          countryName: data.country_name,
          ipAddress: data.IPv4,
          latitude: data.latitude,
          longitude: data.longitude,
          postal: data.postal,
          state: data.state,
          loginTimeZone: timezone,
          loginTime:dateAsString,
          exactBrowser:browserName,
          browserName: window.navigator.appName,
          browserEngine: window.navigator.product,
          timeOpened: new Date(),
          timezone: new Date().getTimezoneOffset() / 60,
          pageon: window.location.pathname,
          referrer: document.referrer,
          previousSites: window.history.length,
          browserName: window.navigator.appName,
          browserEngine: window.navigator.product,
          browserVersion1a: window.navigator.appVersion,
          browserVersion1b: navigator.userAgent,
          browserLanguage: navigator.language,
          browserOnline: navigator.onLine,
          browserPlatform: navigator.platform,
          javaEnabled: navigator.javaEnabled(),
          dataCookiesEnabled: navigator.cookieEnabled,
          dataCookies1: document.cookie,
          dataCookies2: decodeURIComponent(document.cookie.split(';')),
          sizeScreenW: window.screen.width,
          sizeScreenH: window.screen.height,
          sizeAvailW: window.screen.availWidth,
          sizeAvailH: window.screen.availHeight,
          scrColorDepth: window.screen.colorDepth,
          scrPixelDepth: window.screen.pixelDepth,
          continentData: Intl.DateTimeFormat().resolvedOptions().timeZone
      });  
      }).catch(error => {
        console.log(error);
      });
    
ReactDOM.render(<App />, document.getElementById('root'));

serviceWorker.unregister();
