import React, { useState, useEffect } from "react";
import '../project_card_details/style.css';
import {
  Col,
  Row,
  Card,
  Button,
  Image
} from 'react-bootstrap';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {ToastsContainer, ToastsStore} from 'react-toasts';
import GitLogo from '../../assets/img/git.svg';


const PCD = () => {
  const [setErrors] = useState(false);
  const [git, setGit] = useState([]);

  async function fetchMoviesJSON() {
    const response = await fetch('https://api.github.com/users/SunilVijayan7/repos');
    const data = await response.json();
    return data;
  }
  fetchMoviesJSON().then(data => {
    setGit(data);
    Object.keys(data).map(function(keyName, keyIndex) {
      // use keyName to get current key's name
      // and a[keyName] to get its value
      console.log(data[keyName]);
    });
  }).catch(error => {
    console.log(error);
  });

  async function fetchData() {
    //const res = await fetch("https://api.github.com/users/SunilVijayan7/repos?access_token=$GITHUB_API_TOKEN");
    //const res = await fetch("https://api.github.com/users/SunilVijayan7/repos").then(res => (res.ok ? res : Promise.reject(res)))
   // .then(res => res.json());
    //res.json().then(res => setGit(res)).catch(err => setErrors(err));
    }

  useEffect(() => {
    fetchData();
  });
  console.log('git- '+ git);
  
  return (
    <Row>
     {
     git.map(item => (
       <Col xl={12} key = {item.id} className="text-center">
            <Card className="pro_card">
            <Row >
              <Col xl={12} className="project_img_container mt-2">
              <Image className="project_card_left_img" src={GitLogo}/>
              </Col>
            <Col xl={12}>
              <Card.Title className="repoName mt-2">
               Name: {item.name} 
              </Card.Title>
              <Card.Subtitle className="repoInfo mt-2">
                Location: {item.full_name} <br/>
                Created: {item.created_at} <br/>
                Updated: {item.updated_at} 
              </Card.Subtitle>
            <Card.Body className="project_card_main">
                  <Button variant="outline-light" size="lg" className="modal-exit-btn about_modal mr-3 ml-3">
                    <CopyToClipboard text={item.ssh_url}
                      onCopy={() => ToastsStore.info("SSH url copied to clipboard 🤩😍")}>
                      <span>SSH</span>
                    </CopyToClipboard>  
                  </Button>
                  <Button variant="outline-light" size="lg" className="modal-exit-btn about_modal mr-3 ml-3">
                    <CopyToClipboard text={item.clone_url}
                      onCopy={() => ToastsStore.info("HTTPS url copied to clipboard 👍😬")}>
                      <span>HTTPS</span>
                    </CopyToClipboard>  
                  </Button>
                  <Button variant="outline-light" size="lg" className="modal-exit-btn about_modal mr-3 ml-3"> 
                  <a href={item.svn_url} style={{ textDecoration: 'none', color: 'white' }} target="_blank" rel="noopener noreferrer">VIEW</a>
                  </Button>
                  <ToastsContainer store={ToastsStore} timer = '10000' />
            </Card.Body>
            </Col>
            </Row>
            </Card>
           </Col>
          ))
          }
    </Row>
  );
};
export default PCD;
