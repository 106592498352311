import React, { Component } from 'react';
import '../cv_img/style.css';
import cv1 from '../../assets/img/cv1.jpg';
import cv2 from '../../assets/img/cv2.jpg';
//import cv3 from '../../assets/img/cv3.png';
//import cv4 from '../../assets/img/cv4.png';
//import cv from '../../assets/img/cv.png';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; 
import Roll from 'react-reveal/Roll';
import Zoom from 'react-reveal/Zoom';
import Flip from 'react-reveal/Flip';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import {Link, useHistory} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const images = [
  cv1,cv2
];

export default class CRI extends Component {
  constructor(props) {
    super(props);
    this.state = {
      photoIndex: 0,
      isOpen: false,
    };
  }

  render() {
    const { photoIndex, isOpen } = this.state;
    return (
      <div className="row">
      <div className="col-xl-12 box">
        <div className="col-xl-4 float-left unique_top">
            <h4 className="ml-5 mt-3 icon resume-text"> About Me </h4>
            </div>
        <div className="col-md-4 float-right unique_top">
        <Link to="/contact" className="right"><FontAwesomeIcon icon={faDownload} className="left_icon" /><br/><br/>Downlaod PDF</Link>
        <a href="https://www.linkedin.com/in/sunil-vijayan-az/" target="_blank" rel="noopener noreferrer" className="right">
        <FontAwesomeIcon className="left_icon" icon={faLinkedin} /> <br/><br/>LinkedIn
        </a>
        <a href="https://www.github.com/sunilvijayan7/" target="_blank" rel="noopener noreferrer" className="right">
        <FontAwesomeIcon className="left_icon" icon={faGithub} /> <br/><br/>Github
        </a>
        </div>
      </div>
        <div className="overflow-set">
          <div className="container">
            <div className="col-xl-12 text-center alignItems unique_top" style={{"color":"White"}}>
            <h1 align="center" className='about-header mb-2'>Hi 👋, I'm Sunil Vijayan </h1>
            <h2 align="center" className='about-header2 mb-3'>CTO, Doctors Group Ltd</h2>
            <h3 align="center" className='about-header3 mb-3'>Product & Project Architect | Software Architect | Cloud Architect (Azure,AWS,Alibaba,GCP,Heroku) | Mentor</h3>
            <h4 align="center" className='about-header4 mb-3'>Head of the Technology with 13 years of solid experince in Cloud, SAAS, BigData, Machine Learning,
            Product Engineering, Architecture, Cyber Security,AI and Robotics.</h4>
            <p>
            👋👋👋 NB:- All my new projects are listed in my portfolio. 
            </p>
            <div className="row"> 
              <div className="col-md-7">
                <a href="https://github.com/ryo-ma/github-profile-trophy"><img src="https://github-profile-trophy.vercel.app/?username=sunilvijayan7&theme=juicyfresh" alt="sunilvijayan7" /></a> 
                </div>
              <div className="col-md-5">
                <img  src="https://github-readme-stats.vercel.app/api/top-langs?username=sunilvijayan7&show_icons=true&locale=en&layout=compact" alt="sunilvijayan7" />
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-md-6">&nbsp;<img  src="https://github-readme-stats.vercel.app/api?username=sunilvijayan7&show_icons=true&locale=en" alt="sunilvijayan7" /></div>
              <div className="col-md-6"><img  src="https://github-readme-streak-stats.herokuapp.com/?user=sunilvijayan7&" alt="sunilvijayan7" /></div>
            </div>
            <p className='mb-2 mt-2'>- 💻 All of my projects are available at http://github.com/sunilvijayan7

            </p><p className='mb-2'>- 📫 How to reach me - sunil.vijayan@outlook.com</p>
            {/* <img src={cv1} onClick={() => this.setState({ isOpen: true })} className="cv_img_mouse" alt="Sunil Vijayan CV preview"/> */}
            </div>
          </div>
          <div className="row">
            <div className="col-xl-12 text-center alignItems unique_top">
            {/* <img src={cv2} onClick={() => this.setState({ isOpen: true })} className="cv_img_mouse" alt="Sunil Vijayan CV preview"/> */}
            </div>
          </div>
        </div>
      </div>
     
      
      // <Roll right>
      //   <Zoom right> 
      //   <Flip right>
      //   <div>
      //     <img src={cv} onClick={() => this.setState({ isOpen: true })} className="cv_img_mouse" alt="Sunil Vijayan CV preview"/>
      //       {isOpen && (
      //           <Lightbox
      //             mainSrc={images[photoIndex]}
      //             nextSrc={images[(photoIndex + 1) % images.length]}
      //             prevSrc={images[(photoIndex + images.length - 1) % images.length]}
      //             onCloseRequest={() => this.setState({ isOpen: false })}
      //             onMovePrevRequest={() =>
      //               this.setState({
      //                 photoIndex: (photoIndex + images.length - 1) % images.length,
      //               })
      //             }
      //             onMoveNextRequest={() =>
      //               this.setState({
      //                 photoIndex: (photoIndex + 1) % images.length,
      //               })
      //             }
      //           />
      //         )}
      //   </div>
      //   </Flip>
      //   </Zoom>
      // </Roll>
     
    );
  }
}