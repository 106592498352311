import React from 'react';
import '../home_left/style.css';
import izi from '../../assets/img/izi.png'
import envy from '../../assets/img/envy.png'
import enpayroll from '../../assets/img/enpayroll.png'
import olimpica from '../../assets/img/olimpica.png'
import plusvalia from '../../assets/img/plusvalia.png'
import docdoc from '../../assets/img/docdoc.png'
import { Container, Row, Col, Button } from 'react-bootstrap';
import ReactTypingEffect from 'react-typing-effect';
import CLC from '../cv_left_content';
import TechStack from '../tech_stack';
import SocialIcons from '../social_icons';
import {
  Link
} from "react-router-dom";

function Home_Left() {
  return (
    <Container className="home-left" >
      <Row className="home-left-main">
        <Col xl={12} className="home-left-main-col">
          <h1 className="first-line">
            Hello!
          </h1>
          <h2 className="second_line">
            I am <strong>Sunil Vijayan</strong>
          </h2>
          <h3 className="typical mt-2 mb-2">
            Chief Technology Officer<span className="doctorsgroup">@Doctors Group Ltd</span>
          </h3>
          <h3>
            <ReactTypingEffect
              text="Product & Project Architect | Software Architect | Cloud Architect (Azure,AWS,Alibaba,GCP,Heroku) | Mentor" //text=["Hello.", "World!"]
              className="typical"
              speed="70"
              eraseDelay="50000"
            />
          </h3>
          <Col xl={12} className=""><TechStack></TechStack></Col>
          <CLC></CLC>
        </Col>
        <Col xl={12}>
          <Col xl={12}>
            <div className='d-flex w-100 justify-content-center flex-wrap'>
            <h6 className='live-projects-header'>Top Live Projects</h6>
            </div>
          </Col>
          <Col xl={12}>
            <div className='d-flex w-100 justify-content-center'>
              <div className="p-2">
                <figure>
                  <a href="https://izi.health" target="_blank">
                    <img src={izi} alt="alibaba" className="live-projects-dimension" />
                  </a>
                  <figcaption className='live-projects-text'>IZI Health</figcaption>
                </figure>
              </div>
              <div className="p-2">
              <figure>
              <a href="https://sunilvijayan7.github.io/envy/" target="_blank"><img src={envy} alt="alibaba" className="live-projects-dimension" /></a>
                  <figcaption className='live-projects-text'>Envy</figcaption>
                </figure>
              </div>
              <div className="p-2">
              <figure>
              <a href="https://www.olimpica.com/" target="_blank"><img src={olimpica} alt="alibaba" className="live-projects-dimension" /></a>
                  <figcaption className='live-projects-text'>Olimpica</figcaption>
                </figure>
              </div>
              <div className="p-2">
              <figure>
              <a href="http://enpayroll.entvickler.com/" target="_blank"><img src={enpayroll} alt="alibaba" className="live-projects-dimension" /></a>
                  <figcaption className='live-projects-text'>ENPayroll</figcaption>
                </figure>
              </div>
              <div className="p-2">
              <figure>
              <a href="https://www.plusvalia.com/" target="_blank"><img src={plusvalia} alt="alibaba" className="live-projects-dimension" /></a>
                  <figcaption className='live-projects-text'>Plusvalia</figcaption>
                </figure>
              </div>
              <div className="p-2">
              <figure>
              <a href="https://www.doc-doc.com/?" target="_blank"><img src={docdoc} alt="alibaba" className="live-projects-dimension" /></a>
                  <figcaption className='live-projects-text'>Doc Doc</figcaption>
                </figure>
              </div>
              
            </div>
          </Col>
        </Col>
      </Row>

    </Container>
  );
}

export default Home_Left;
