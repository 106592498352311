import React, { useState, useEffect }  from 'react';
import '../home/style.css';
import HomeLeft from '../../components/home_left';
import HomeRight from '../../components/home_right';
import Footer from '../../components/footer';
import { Container, Row, Col, } from 'react-bootstrap';
import Animate from 'react-smooth';
import Particles from 'react-particles-js';
import particles from '../../const/particle.js';
import ReactGa from 'react-ga';
import {Helmet} from 'react-helmet';

import GeoLocation from '../../components/geo_locate';

//--Sunil Vijayan's Portfolio website Google analytics--
//--Replace with your own key !!
function Analytics(){
  ReactGa.initialize("UA-194376061-2")
  ReactGa.pageview('SunilVijayan.com - Home Screen')
}
function Home() {
  Analytics()
  return (
      <div>
         <Helmet>
                  <meta charSet="utf-8" />
                  <title>Home | Sunil Vijayan</title>
                  <link rel="canonical" href="http://SunilVijayan.com/home" />
                  <meta name="description" content="Sunil Vijayan | Home - Project & Program Lead , Technical Project Manager, Lead Software Engineer, Azure Cloud Expert" />
         </Helmet>
          <Animate to="1" from="0" attributeName="opacity">
            <Particles
            params={particles}
            className="particle"
            />
          <Container className="App-header" fluid={true}>
            <Row className="App-main">
              <Col  xl={7}  className="App-left">
                <HomeLeft />
              </Col>
              <Col  xl={5} className="App-right">
                <HomeRight />
              </Col>
            </Row>
            <Row className="App-footer">
              <Footer />
            </Row>
        </Container>
        </Animate>
        {/* <GeoLocation></GeoLocation> */}
      </div>
    );
}

export default Home;
