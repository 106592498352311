import React from 'react';
import '../tech_stack/style.css';
import { Col, } from 'react-bootstrap';
import azure from '../../assets/img/devicons/azure.svg'
import aws from '../../assets/img/devicons/aws.svg'
import alibaba from '../../assets/img/devicons/alibaba.png'
import gcp from '../../assets/img/devicons/gcp.svg'
import dotnetcore from '../../assets/img/devicons/dotnetcore.svg'
import nodejs from '../../assets/img/devicons/nodejs.svg'
import reactjs from '../../assets/img/devicons/reactjs.svg'
import graphql from '../../assets/img/devicons/graphql.svg'
import flutter from '../../assets/img/devicons/flutter.svg'
import angularjs from '../../assets/img/devicons/angularjs.svg'
import android from '../../assets/img/devicons/android.svg'
import androidstudio from '../../assets/img/devicons/androidstudio.svg'
import dart from '../../assets/img/devicons/dart.svg'
import csharp from '../../assets/img/devicons/csharp.svg'
import apple from '../../assets/img/devicons/apple.png'
import bootstrap from'../../assets/img/devicons/bootstrap.svg'
import firebase from'../../assets/img/devicons/firebase.svg'
import python from'../../assets/img/devicons/python.svg'
import java from'../../assets/img/devicons/java.svg'
import javascript from'../../assets/img/devicons/javascript.svg'
import html5 from'../../assets/img/devicons/html5.svg'
import css from'../../assets/img/devicons/css.svg'
import sass from'../../assets/img/devicons/sass.svg'
import github from'../../assets/img/devicons/github.svg'
import sqlite from'../../assets/img/devicons/sqlite.svg'
import sql from'../../assets/img/devicons/sql.png'
import postgresql from'../../assets/img/devicons/postgresql.svg'
import mongodb from'../../assets/img/devicons/mongodb.svg'
import heroku from'../../assets/img/devicons/heroku.svg'
import figma from'../../assets/img/devicons/figma.svg'
import xd from'../../assets/img/devicons/xd.svg'
import sketch from'../../assets/img/devicons/sketch.svg'
import photoshop from'../../assets/img/devicons/photoshop.svg'
import postman from'../../assets/img/devicons/postman.png'
import redis from'../../assets/img/devicons/redis.svg'
import cosmosdb from'../../assets/img/devicons/cosmosdb.png'

function TechStack() {
  return (
    <Col xl={12} className="social-icons">
      <div className='d-flex w-100 justify-content-center flex-wrap'>
      <div className="p-1"><a><img src={azure}  className="dimension" /></a></div>
      <div className="p-1"><a><img src={aws}  className="dimension" /></a></div>
      <div className="p-1"><a><img src={alibaba}  className="dimension" /></a></div>
      <div className="p-1"><a><img src={gcp}  className="dimension" /></a></div>
      <div className="p-1"><a><img src={nodejs}  className="dimension" /></a></div>
      <div className="p-1"><a><img src={reactjs}  className="dimension" /></a></div>
      <div className="p-1"><a><img src={graphql}  className="dimension" /></a></div>
      <div className="p-1"><a><img src={flutter}  className="dimension" /></a></div>
      <div className="p-1"><a><img src={angularjs}  className="dimension" /></a></div>
      <div className="p-1"><a><img src={dotnetcore}  className="dimension" /></a></div>
      <div className="p-1"><a><img src={android}  className="dimension" /></a></div>
      <div className="p-1"><a><img src={androidstudio}  className="dimension" /></a></div>
      <div className="p-1"><a><img src={csharp}  className="dimension" /></a></div>
      <div className="p-1"><a><img src={dart}  className="dimension" /></a></div>
      <div className="p-1"><a> <img src={apple}  className="dimension" /> </a></div>
      <div className="p-1"><a><img src={bootstrap}  className="dimension" /></a></div>
      <div className="p-1"><a><img src={firebase}  className="dimension" /></a></div>
      <div className="p-1"><a><img src={redis}  className="dimension" /></a></div>
      </div>
      <div className='d-flex w-100 justify-content-center flex-wrap'>
        <div className="p-1"><a><img src={cosmosdb}  className="dimension" /></a></div>
        <div className="p-1"><a><img src={python}  className="dimension" /></a></div>
        <div className="p-1"><a><img src={java}  className="dimension" /></a></div>
        <div className="p-1"><a><img src={javascript}  className="dimension" /></a></div>
        <div className="p-1"><a><img src={html5}  className="dimension" /></a></div>
        <div className="p-1"><a><img src={css}  className="dimension" /></a></div>
        <div className="p-1"><a><img src={sass}  className="dimension" /></a></div>
        <div className="p-1"><a><img src={github}  className="dimension" /></a></div>
        <div className="p-1"><a><img src={sql}  className="dimension" /></a></div>
        <div className="p-1"><a><img src={sqlite}  className="dimension" /></a></div>
        <div className="p-1"><a><img src={postgresql}  className="dimension" /></a></div>
        <div className="p-1"><a><img src={mongodb}  className="dimension" /></a></div>
        <div className="p-1"><a><img src={heroku}  className="dimension" /></a></div>
        <div className="p-1"><a><img src={figma}  className="dimension" /></a></div>
        <div className="p-1"><a><img src={xd}  className="dimension" /></a></div>
        <div className="p-1"><a><img src={sketch}  className="dimension" /></a></div>
        <div className="p-1"><a><img src={photoshop}  className="dimension" /></a></div>
        <div className="p-1"><a><img src={postman}  className="dimension" /></a></div>
      </div>
    </Col>
  );
}

export default TechStack;